import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import ken from "../../static/img/nikhita-s-615116-unsplash.jpg";
import yarnlogo from "../img/YarnBack.png";
import tcflogo from "../img/TcfBack.png";

import "./index.sass";

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const content = data.markdownRemark['html']
    // console.log(data)
    return (
      <Layout>
        {/* <div id="imageContainer">
          <img src={ken} alt="header" />
        </div> */}
        <section className="section">
          <div className="container">
            <div className="columns is-8">
              <div id="maincontent" className="column is-10 is-offset-1">
                <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-8">
              <div id="aboutyarn" className="column is-5 is-offset-1">
                <Link to="/p/achtergrond" className="aboutbox overlay red">
                  <div className="content">
                    <h1>Discover the story of the red yarn</h1>
                  </div>
                  <div className="backcontent">
                    <img src={yarnlogo} alt="header" />
                  </div>
                </Link>
              </div>
              <div id="abouttcf" className="column is-5">
                <a
                  className="aboutbox overlay red"
                  href="https://www.tcf.org.pk/#our-work"
                  target="_blank"
                >
                  <div className="content">
                    <h1>explore our way of education</h1>
                  </div>
                  <div className="backcontent">
                    <img src={tcflogo} alt="header" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section">
          <div className="container">
            <div className="content">
              <h1 className="is-size-2 has-text-centered">Latest Blogs</h1>
            </div>
            <div className="columns">
              {posts.map(({ node: post }) => (
                <div className="content column is-4" key={post.id}>
                  <div className="news-image">
                    <img
                      src={post.frontmatter.featured}
                      alt={post.frontmatter.title}
                    />
                    <div className="news-image-hover">
                      <Link
                        to={post.fields.slug}
                        className="button is-primary is-medium is-outlined"
                      >
                        Keep Reading →
                      </Link>
                    </div>
                  </div>
                  <Link to={post.fields.slug}>
                    <div className="news-title">
                      <p>{post.frontmatter.date}</p>
                      {post.frontmatter.title}
                    </div>
                  </Link>
                  {/*  <div className="news-content">
                      <p>
                        {post.excerpt}
                      </p>
                    </div>
                </div>
              ))}
            </div>
            <div className="has-text-centered" style={{ marginTop: "-15px" }}>
              <Link
                className=" button is-primary is-outlined is-medium"
                to="/blog"
              >
                View all
              </Link>
            </div>
          </div>
        </section> */}
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
      html
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featured
            templateKey
            date(formatString: "MMMM DD, YYYY")
          } 
        }
      }
    }
  }
`;
